// Example of a simple Home component in src/pages/Home.js

function Home() {
    return (
        <div>
            <h1>Welcome to My Portfolio</h1>
            <p>I'm James Hunt, a third year student currently at Duke University.</p>
        </div>
    );
}

export default Home;