import React from 'react';

const AlgorithmicTrading = () => {
    return (
        <div className="algorithmic-trading-page">
            <iframe
                src="/part_b.html"
                title="Algorithmic Trading"
                style={{ width: '100%', height: '100vh', border: 'none' }}
            />
        </div>
    );
};

export default AlgorithmicTrading;