import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <p>James Hunt © 2024</p>
            <p>Email: james.hunt940@icloud.com</p>
        </footer>
    );
}


export default Footer;
