function About() {
    return (
        <div>
            <h1>This is about me.</h1>
            <p>
                I was born and raised in Syracuse, New York.
            </p>
        </div>
    );
}
export default About;


