import React from 'react';

const AlgoTrading = () => {
    return (
        <div className="algorithmic-trading-page">
            <iframe
                src="/part_b_revised.html"
                title="Algorithmic Trading 2"
                style={{ width: '100%', height: '100vh', border: 'none' }}
            />
        </div>
    );
};

export default AlgoTrading;